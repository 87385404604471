/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Vollkorn+SC:wght@400;600;700;900&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
:root {
  --background: #ffffff;
  --foreground: #171717;
} */

/* @media (prefers-color-scheme: dark) {
  :root {
    --background:  #ededed;
    --foreground:#0a0a0a;
  }
} */
/* @font-face{
  font-family:'Source Sans 3';
  src:url('fonts/SourceSans3-VariableFont_wght.ttf') format('ttf');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face{
  font-family:'Cinzel';
  src:url('fonts/Cinzel-VariableFont_wght.ttf') format('ttf');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
} 

:root {
  --banner-height: 250px;
}

@media (min-width: 640px) {
  :root {
    --banner-height: 250px;
  }
}

@media (min-width: 1024px) {
  :root {
    --banner-height: 800px;
  }
}*/

body {
  /* color: var(--foreground);
  background: var(--background); */
  font-family: 'Source Sans 3', sans-serif;   
}

@layer utilities {
  /* .text-balance {
    text-wrap: balance;
  } */
  .stitle{
    font-family: 'Rajdhani', sans-serif; 
    text-transform: capitalize;
    font-weight: bold;
    font-display: swap;
    /* src:url('fonts/Rajdhani-Regular.ttf') format('ttf'); */

  }
  .stitle::first-letter{
    font-size: x-large;
  }
  .vollkorn-sc-regular {
    font-family: "Vollkorn SC", serif;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    /* src:url('fonts/VollkornSC-Regular.ttf') format('ttf'); */
  }
  .vollkorn-sc-semibold {
    font-family: "Vollkorn SC", serif;
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    /* src:url('fonts/VollkornSC-SemiBold.ttf') format('ttf'); */
  }
  
  .vollkorn-sc-bold {
    font-family: "Vollkorn SC", serif;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    /* src:url('fonts/VollkornSC-Bold.ttf') format('ttf'); */
  }
  .line-height-increased {
    line-height: 2.5; /* Adjust the value as needed */
  }
  .first-letter {
    font-size: 130%;
  }
  .last-bracket-s{
    font-size: 90%;
  }
}